import React from "react";
import { SmartLink } from "../components/core/link/smartlink";
import { Layout } from "../components/layout";
import { PageHeaderSection } from "../components/rebrand/sections/PageHeader/PageHeaderSection";
import { SEO } from "../components/seo";

function Error404Page() {
  return (
    <Layout>
      <SEO />
      <PageHeaderSection
        title="Página no encontrada"
        subtitle={
          <>
            Vuelve <SmartLink label="al inicio" path="/" /> o {" "}
            <SmartLink label="envíanos un mensaje" path="/contact/" /> si crees que esto es un error. ¡Gracias!
            for.
          </>
        }
      />
    </Layout>
  );
}

export default Error404Page;
